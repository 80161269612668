@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Salsa&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

h1, h2, h3 {
  font-family: "Poppins", serif;
}
p {
  font-family: "Open Sans", sans-serif;  /* Fallback to Open Sans for paragraphs */
}


.a1 {
  width: 50% " aria-valuenow=" 50 " aria-valuemin=" 0 " aria-valuemax=" 100 ";

}

.a2 {
  width: 20%" aria-valuenow= " 20" aria-valuemin= " 0" aria-valuemax=
 "100";
}

.a3 {
  width: 40% " aria-valuenow=" 40 " aria-valuemin=" 0 " aria-valuemax=" 100 ";

}

.a4 {
  width: 80%" aria-valuenow= " 80" aria-valuemin= " 0" aria-valuemax=
 "100";
}

.a5 {
  width: 100% " aria-valuenow=" 100 " aria-valuemin=" 0 " aria-valuemax=" 100 ";

}

.a6 {
  width: 25rem;
}

.a7 {
  width: 60%" aria-valuenow= " 60"
 aria-valuemin="0" aria-valuemax="100";
}

.toggled {
  overflow: visible;
  width: 6.5rem !important;
}

.toggled1 {
  width: 0 !important;
  overflow: hidden;
}

/* .my_dashboard_main_div{
    width: 25% !important;
} */
.my_pie_chart_class {
  display: flex;
  justify-content: center;
}

.welcome_heading {
  text-decoration: none !important;
  color: #000 !important;
}

.dashboard_all_category a {
  text-decoration: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.side_bar_user_name {
  color: #cfcfcf;
}

.customer_details_form_div p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.customer_details_form_div p span {
  font-weight: 300 !important;
}


table thead tr th{
  font-weight: 500;
}
.drop_down_login a{
  text-decoration: none !important;
}
.welcome_back_change a{
color: #000 !important;
text-decoration: none !important;
}