/* Your custom styles for the accordion items */
.my-custom-accordion-item {
    /* Add your custom styles here */
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
 }
 
 .my-custom-accordion-item .accordion-button {
    /* Add your custom styles for accordion buttons here */
    background-color: #f8f9fa;
    color: #343a40;
 }
 
 .my-custom-accordion-item .accordion-body {
    /* Add your custom styles for accordion body here */
    padding: 15px;
    background-color: #fff;
 }
 